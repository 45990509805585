import throttle from 'lodash/throttle';
import { Controller } from 'stimulus';

export default class extends Controller {
  initialize() {
    this.mobileHeader = document.querySelector('.js-mobile-header');
    this.hamburgerToggle = document.querySelectorAll('.js-hamburger-toggle');
    this.navItems = document.querySelectorAll('.js-nav-items .btn');
    this.hamburgerToggle.forEach((el) => {
      el.addEventListener('click', this.toggleMobileMenu.bind(this));
    });
    this.navItems.forEach((el) => {
      el.addEventListener('click', this.closeDrawer.bind(this));
    });
    window.addEventListener('scroll', throttle(this.pagescroll, 250));
    window.addEventListener('load', this.handlePageLoad.bind(this));
    window.addEventListener(
      'resize',
      throttle(() => {
        if (window.location.hash) {
          this.smoothScrollToAnchor(window.location.hash);
        }
      }, 250)
    );
  }

  handlePageLoad() {
    const hash = window.location.hash;
    if (hash) {
      this.smoothScrollToAnchor(hash);
    }
  }

  smoothScrollToAnchor(selector) {
    const targetElement = document.querySelector(selector);
    if (targetElement) {
      setTimeout(() => {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }

  pagescroll() {
    let body = document.body;
    let header = document.querySelector('#header-main');
    let footer = document.querySelector('footer.site-footer');
    let viewport = {
      top: window.scrollY,
      bot: window.scrollY + window.innerHeight,
    };

    // Page has started scrolling
    let isScrolling = viewport.top > 320;
    body.classList.toggle('-page-scrolling', isScrolling);
    header.classList.toggle('-page-scrolling', isScrolling);

    // Page is at the bottom near the footer
    let nearBottom = viewport.bot > footer.offsetTop;
    body.classList.toggle('-page-bottom', nearBottom);
  }

  closeDrawer() {
    this.mobileHeader.classList.remove('is-expanded');
    this.hamburgerToggle.forEach((el) => {
      el.classList.remove('open');
      el.setAttribute('aria-expanded', false);
    });
    document.body.classList.remove('-is-scroll-locked');
  }

  toggleMobileMenu() {
    this.mobileHeader.classList.toggle('is-expanded');
    let isOpen = this.mobileHeader.classList.contains('is-expanded');
    this.hamburgerToggle.forEach((el) => {
      el.classList.toggle('-close-icon');
      el.setAttribute('aria-expanded', isOpen);
    });
  }
}
